@use "../utils/functions.scss" as *;
@use "../utils/breakpoints.scss" as *;

.container {
  max-width: rem(1110);
  padding-left: rem(23);
  padding-right: rem(23);
  margin: 0 auto rem(50);
  @include breakpointsUp(tablet) {
    padding: 0;
  }
}
