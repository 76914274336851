@use "../utils/functions.scss" as *;
@use "../utils/breakpoints.scss" as *;

.card {
  text-align: center;
  @include breakpointsUp(desktop) {
    max-width: rem(254);
  }
  padding: rem(28) rem(64) rem(26) rem(64);
  background-color: var(--card-bg);
  border-radius: rem(5);
  cursor: pointer;
  transition: all 150ms ease-in-out;
  &:hover {
    background-color: var(--card-hover);
  }
  //first group of cards styles.
  &__platform {
    margin-bottom: rem(30);
    &--icon {
      vertical-align: middle;
      margin-right: rem(9);
    }
    &--username {
      display: inline-block;
      font-size: rem(12);
      color: var(--text);
    }
  }

  &__followers {
    margin-bottom: rem(30);
    &--count {
      font-size: rem(43);
      font-weight: 700;
      color: var(--text-header);
      margin-bottom: rem(16);
    }
    &--label {
      font-size: rem(9);
      text-transform: uppercase;
      letter-spacing: rem(7);
      color: var(--text);
    }
  }

  &__change {
    &--icon__up,
    &--icon__down {
      vertical-align: middle;
    }
    &--number {
      display: inline-block;
      font-size: rem(9);
      vertical-align: middle;
      &--up {
        color: var(--LimeGreen);
      }
      &--down {
        color: var(--BrightRed);
      }
    }
  }
  //overview section
  &__header {
    margin-bottom: rem(27);
    position: relative;
    &--label {
      display: inline-block;
      vertical-align: middle;
      font-size: rem(14);
      color: var(--text);
    }
    &--icon {
      vertical-align: middle;
      position: absolute;
      top: rem(-5);
      right: 0;
    }
  }
  &__views {
    align-items: baseline;
    &--count {
      font-size: rem(24);
      font-weight: 700;
      color: var(--text-header);
    }
  }
}
