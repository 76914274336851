@use "../utils/functions.scss" as *;
* {
  font-size: 100%;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter), sans-serif;
  color: var(--White_Text);
  background-color: var(--background);
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
