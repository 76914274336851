* {
  font-size: 100%;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter), sans-serif;
  color: var(--White_Text);
  background-color: var(--background);
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:root {
  --LimeGreen: hsl(163, 72%, 41%);
  --BrightRed: hsl(356, 69%, 56%);
  --Facebook: hsl(208, 92%, 53%);
  --Twitter: hsl(203, 89%, 53%);
  --Instagram_start: hsl(37, 97%, 70%);
  --Instagram_end: hsl(329, 70%, 58%);
  --YouTube: hsl(348, 97%, 39%);
  --Toggle_dark_start: hsl(210, 78%, 56%);
  --Toggle_dark_end: hsl(146, 68%, 55%);
  --Toggle_light: hsl(230, 22%, 74%);
  --VeryDarkBlue_BG: hsl(230, 17%, 14%);
  --VeryDarkBlue_TopBGPattern: hsl(232, 19%, 15%);
  --DarkDesaturatedBlue_CardBG: hsl(228, 28%, 20%);
  --dark-card-hover: hsl(228, 25%, 27%);
  --DesaturatedBlue_Text: hsl(228, 34%, 66%);
  --White_Text: hsl(0, 0%, 100%);
  --White_BG: hsl(0, 0%, 100%);
  --VeryPaleBlue_TopBGPattern: hsl(225, 100%, 98%);
  --LightGrayishBlue_CardBG: hsl(227, 47%, 96%);
  --light-card-hover: hsl(228, 33%, 91%);
  --DarkGrayishBlue_Text: hsl(228, 12%, 44%);
  --VeryDarkBlue_Text: hsl(230, 17%, 14%);
  --header-bg-dark: hsl(232, 19%, 15%);
  --header-bg-light: hsl(230, 75%, 98%);
  --background: var(--VeryDarkBlue_BG);
  --card-bg: var(--DarkDesaturatedBlue_CardBG);
  --header-bg: var(--header-bg-dark);
  --card-hover: var(--dark-card-hover);
  --text-header: var(--White_Text);
  --text: var(--DesaturatedBlue_Text);
  --toggle-bg: linear-gradient(
    225deg,
    var(--Toggle_dark_start) 0%,
    var(--Toggle_dark_end) 98.02%
  );
  --toggle-btn: var(--DarkDesaturatedBlue_CardBG);
}

.light {
  --background: var(--White_BG);
  --card-bg: var(--LightGrayishBlue_CardBG);
  --header-bg: var(--header-bg-light);
  --card-hover: var(--light-card-hover);
  --text-header: var(--VeryDarkBlue_Text);
  --text: var(--DarkGrayishBlue_Text);
  --toggle-bg: var(--DarkGrayishBlue_Text);
  --toggle-btn: var(--White_BG);
}

.dark {
  --background: var(--VeryDarkBlue_BG);
  --card-bg: var(--DarkDesaturatedBlue_CardBG);
  --header-bg: var(--header-bg-dark);
  --card-hover: var(--dark-card-hover);
  --text-header: var(--White_Text);
  --text: var(--DesaturatedBlue_Text);
  --toggle-bg: linear-gradient(
    225deg,
    var(--Toggle_dark_start) 0%,
    var(--Toggle_dark_end) 98.02%
  );
  --toggle-btn: var(--DarkDesaturatedBlue_CardBG);
}

:root {
  --font-inter: "Inter";
}

.container {
  max-width: 69.375rem;
  padding-left: 1.4375rem;
  padding-right: 1.4375rem;
  margin: 0 auto 3.125rem;
}
@media (min-width: 47.5em) {
  .container {
    padding: 0;
  }
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 1.3125rem;
  margin-bottom: 0.8125rem;
  color: var(--text-header);
}
@media (min-width: 47.5em) {
  h1 {
    font-size: 1.1875rem;
  }
}

p {
  margin: 0;
  font-size: 0.75rem;
}

a,
a:visited,
a:active {
  text-decoration: none;
  color: var(--text-header);
}

body {
  position: relative;
}

.header-bg {
  position: absolute;
  z-index: -1;
  height: 15.25rem;
  width: 100%;
  background: var(--header-bg);
}

.header {
  padding-top: 2.5625rem;
}
@media (min-width: 47.5em) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.header__title {
  border-bottom: solid;
  border-width: 0.0625rem;
  border-color: var(--DarkGrayishBlue_Text);
  padding-bottom: 1.375rem;
}
@media (min-width: 47.5em) {
  .header__title {
    border: none;
    padding-bottom: 0;
  }
}
.header__title p {
  color: var(--text);
}

fieldset {
  border: none;
  margin-top: 1.375rem;
  padding: 0;
}
@media (min-width: 47.5em) {
  fieldset {
    margin: 0;
  }
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 47.5em) {
  .toggle {
    display: block;
  }
}
.toggle label {
  font-size: 0.75rem;
  margin-right: 0.875rem;
  color: var(--text-header);
}
@media (min-width: 47.5em) {
  .toggle label {
    color: var(--text-header);
  }
}
.toggle__wrapper {
  display: inline-block;
  position: relative;
  height: 1.5rem;
  width: 3rem;
  cursor: pointer;
  vertical-align: middle;
}
.toggle__background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--toggle-bg);
  border-radius: 0.75rem;
  overflow: hidden;
  z-index: 0;
}
.toggle input[type=radio] {
  display: inline-block;
  height: 18px;
  margin: 0 3px 0px 0;
  opacity: 0;
  width: 18px;
  z-index: 1;
  position: relative;
  top: 3px;
  left: 3px;
}
.toggle__switcher {
  display: block;
  position: absolute;
  height: 1.125rem;
  width: 1.125rem;
  top: 0.1875rem;
  left: 0.1875rem;
  right: 100%;
  border-radius: 50%;
  background-color: var(--toggle-btn);
  z-index: 2;
  transition: all 150ms ease-out;
}

#toggle__light:checked ~ .toggle__switcher {
  right: 0;
  left: 50%;
}

.card__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
}
@media (min-width: 47.5em) {
  .card__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 64.0625em) {
  .card__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
.card__grid-facebook {
  border-top: 0.25rem solid var(--Facebook);
}
.card__grid-twitter {
  border-top: 0.25rem solid var(--Twitter);
}
.card__grid-instagram {
  position: relative;
  z-index: 2;
}
.card__grid-instagram::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 0.25rem;
  width: 100%;
  background-image: linear-gradient(255deg, var(--Instagram_end) 50.91%, var(--Instagram_start) 100%);
  border-radius: 0.3125rem 0.3125rem 0 0;
}
.card__grid-youtube {
  border-top: 0.25rem solid var(--YouTube);
}

.card {
  text-align: center;
  padding: 1.75rem 4rem 1.625rem 4rem;
  background-color: var(--card-bg);
  border-radius: 0.3125rem;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}
@media (min-width: 64.0625em) {
  .card {
    max-width: 15.875rem;
  }
}
.card:hover {
  background-color: var(--card-hover);
}
.card__platform {
  margin-bottom: 1.875rem;
}
.card__platform--icon {
  vertical-align: middle;
  margin-right: 0.5625rem;
}
.card__platform--username {
  display: inline-block;
  font-size: 0.75rem;
  color: var(--text);
}
.card__followers {
  margin-bottom: 1.875rem;
}
.card__followers--count {
  font-size: 2.6875rem;
  font-weight: 700;
  color: var(--text-header);
  margin-bottom: 1rem;
}
.card__followers--label {
  font-size: 0.5625rem;
  text-transform: uppercase;
  letter-spacing: 0.4375rem;
  color: var(--text);
}
.card__change--icon__up, .card__change--icon__down {
  vertical-align: middle;
}
.card__change--number {
  display: inline-block;
  font-size: 0.5625rem;
  vertical-align: middle;
}
.card__change--number--up {
  color: var(--LimeGreen);
}
.card__change--number--down {
  color: var(--BrightRed);
}
.card__header {
  margin-bottom: 1.6875rem;
  position: relative;
}
.card__header--label {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.875rem;
  color: var(--text);
}
.card__header--icon {
  vertical-align: middle;
  position: absolute;
  top: -0.3125rem;
  right: 0;
}
.card__views {
  align-items: baseline;
}
.card__views--count {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-header);
}

.overview__heading {
  font-size: 1.5rem;
  color: var(--text-header);
  font-weight: 700;
  margin-bottom: 1.4375rem;
}
.overview__cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.9375rem;
}
@media (min-width: 47.5em) {
  .overview__cards {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
.overview__card {
  padding: 1.875rem 1.5rem 1.6875rem;
}