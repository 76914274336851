@use "./functions.scss" as *;
$breakpointsUp: (
  "mobile": "17.5em",
  //280px
  "tablet": "47.5em",
  //760px
  "desktop": "64.0625em",
  //1025px
);
$breakpointsDown: (
  "mobile": "28.125em",
  //450px
  "tablet": "64em",
  //1024px
  "desktop": "90em",
  //1440px
);

@mixin breakpointsUp($size) {
  @media (min-width: map-get($breakpointsUp, $size)) {
    @content;
  }
}
@mixin breakpointsDown($size) {
  @media (max-width: map-get($breakpointsDown, $size)) {
    @content;
  }
}
