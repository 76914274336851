@use "../utils/functions.scss" as *;
@use "../utils/breakpoints.scss" as *;
.overview {
  &__heading {
    font-size: rem(24);
    color: var(--text-header);
    font-weight: 700;
    margin-bottom: rem(23);
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(15);
    @include breakpointsUp(tablet) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
  &__card {
    padding: rem(30) rem(24) rem(27);
  }
}
